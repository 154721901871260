@font-face {
  font-family: 'silka_regular';
  src:  url('./fonts/silka-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'silka_bold';
  src:  url('./fonts/silka-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'light';
  src:  url('./fonts/light.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
}

:root{
  scroll-behavior: smooth;

  --maxWidth: 1200px;

  --color1: rgb(0, 0, 0);
  --color2: rgb(255, 255, 255);
  --color3: rgba(26, 255, 171);

  --color4: rgba(255,255,255,0.4);
  --color5: rgba(16, 16, 16, 1.0);
  --color6: #e9292f;
  --color7: rgba(52, 52, 52, 1);

  --gap05: 5px;
  --gap1: 10px;
  --gap2: 20px;
  --gap3: 30px;
  --gap4: 50px;
  --gap5: 75px;
  --gap6: 100px;
  --gap7: 150px;

  --padding-big: 50px;


  --confirmBtnLength: 200px;
}

body {
  margin: 0;
  font-family: "silka_regular","silka_bold", "roc_grotesk" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color2);
  background: var(--color1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



p{
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 150%;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 20px;
}





/* CONTAINERS UNIVERSAL  */
/* flex / gaps / padding / margin */
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.gap-05 {
  gap: var(--gap05);
}

.gap-1 {
  gap: var(--gap1);
}

.gap-2 {
  gap: var(--gap2);
}

.gap-3 {
  gap: var(--gap3);
}

.gap-4 {
  gap: var(--gap4);
}

.p-1 {
  padding: var(--gap1);
}

.pt-1{
  padding-top: var(--gap1);
}

.pb-1{
  padding-bottom: var(--gap1);
}

.pl-1 {
  padding-left: var(--gap1);
}

.pl-2 {
  padding-left: var(--gap2);
}

.pl-3 {
  padding-left: var(--gap3);
}

.pr-1 {
  padding-right: var(--gap1);
}

.pr-2 {
  padding-right: var(--gap2);
}

.pr-3 {
  padding-right: var(--gap3);
}

.pt-05{
  padding-top: var(--gap05);
}

.pb-05{
  padding-bottom: var(--gap05);
}

.pt-big {
  padding-top: var(--padding-big);
}

.mt-a {
  margin-top: auto;
}

.mt-1 {
  margin-top:  var(--gap1);
}

.mt-2 {
  margin-top:  var(--gap2);
}

.mt-3 {
  margin-top:  var(--gap3);
}

.mt-4 {
  margin-top:  var(--gap4);
}

.mt-5 {
  margin-top:  var(--gap5);
}

.mt-6 {
  margin-top:  var(--gap6);
}

.mt-7 {
  margin-top:  var(--gap7);
}

.mt-big {
  margin-top: 250px;
}

/*  SIZE  */
.width-fit {
  width: fit-content;
}

.width-full {
  width: 100%;
}

.max-width {
  width: 100%;
  max-width: var(--maxWidth);
}



/*BUTTONS*/
.chooseBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: rgba(0,0,0,0.5);
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
}

.sendBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 600;
}

.vysledkyBtn1 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.vysledkyBtn2 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color4);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  display: flex;
}

.nextBtn1 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  aspect-ratio: 1 / 1;
}

.nextBtn2 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor:not-allowed;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  aspect-ratio: 1 / 1;
  opacity: 0.2;
}

.csvBtn {
  padding: var(--gap05);
  border-radius: 5px;
  border: none;
  background: transparent;
  border: 2px solid var(--color2);
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (min-width: 400px) {
  .vysledkyBtn1 {
    font-size: 14px;
    padding: var(--gap1);
  }

  .vysledkyBtn2 {
    font-size: 14px;
    padding: var(--gap1);
  }
}



/* BORDERS */

.border-bottom-1 {
  border-bottom: 1px solid var(--color4);
  border-bottom-style: dashed;
}

/* BARVY */

.background-1 {
  background: var(--color1);
}

.background-2 {
  background: var(--color2);
}

.background-3 {
  background: var(--color3);
}

.background-4 {
  background: var(--color4);
}

/* texty */
.white-text {
  color: var(--color2);
}

.error {
  color: red;
  font-weight: 600;
}

a{
  text-decoration: none;
  color: initial;
}


/* TEXTY */
.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* SCROLLING */
.scrollMarginTop-1 {
  scroll-margin-top: var(--gap2);
}



/* CUSTOM  */

/* HEADER SECTION */
.logo-container {
  padding: 0 12%;
  margin-top: 175px;
}

.logo {
  width: 100%;
}

.logo_img {
  aspect-ratio: 187.2 / 70.9;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;

}

.logo-text {
  font-size: 6.5vw;
  margin-top: var(--gap7);
  font-family: "silka_bold";
  text-align: center;
}

.adress {
  margin: var(--gap2) 0;
}

.address-text {
  font-family: "light";
  text-align: center;
  font-size: initial;
  font-size: 2vw;
}

@media screen and (min-width: 1200px) {
  .logo-container {
    margin-top: var(--gap7);
  }

  .logo-text {
    font-size: 80px;
  }

  .adress {
    margin: var(--gap5) 0;
  }

  .address-text {
    font-size: 25px;
  }
}

.green-text {
  color: var(--color3);

}